import * as d3 from 'd3';

class DataClient {
    static async fetchPriceData({ pair, chainId, from, to, datapoints = 30 }) {
        if (!pair || !chainId || !from || !to || !pair.tokenIn || !pair.tokenOut) return [];
        const endpoint = `https://dexstandard.com/api/v1/ds/${chainId}/prices/${pair.tokenIn.symbol}/${pair.tokenOut.symbol}?from=${from}&to=${to}&datapoints=${datapoints}`;
        try {
            const response = await fetch(endpoint);
            if (!response.ok) {
                throw new Error(`Error fetching data: ${response.statusText}`);
            }
            const data = await response.json();
            return data.prices.map((price) => ({
                date: new Date(price.date),
                close: parseFloat(price.average),
                high: parseFloat(price.high),
                low: parseFloat(price.low),
            }));
        } catch (error) {
            console.error("Failed to fetch price data:", error);
            return [];
        }
    }

    static async getRealDataForRange(from, to, datapoints = 100) {
        return await this.fetchPriceData({
            from,
            to,
            datapoints: Math.min(datapoints, 100),
        });
    }

    static filterDataByRange(data, selectedRange) {
        const endDate = d3.max(data, (d) => d.date);
        let startDate;

        switch (selectedRange) {
            case '1W':
                startDate = d3.timeWeek.offset(endDate, -1);
                break;
            case '1M':
                startDate = d3.timeMonth.offset(endDate, -1);
                break;
            case '3M':
                startDate = d3.timeMonth.offset(endDate, -3);
                break;
            case '6M':
                startDate = d3.timeMonth.offset(endDate, -6);
                break;
            case '1Y':
                startDate = d3.timeYear.offset(endDate, -1);
                break;
            default:
                startDate = d3.timeMonth.offset(endDate, -1);
        }

        return data.filter((d) => d.date >= startDate && d.date <= endDate);
    }

    static calculateSMA(data, windowSize) {
        return data.map((d, idx, arr) => {
            if (idx < windowSize - 1) return null;
            const slice = arr.slice(idx - windowSize + 1, idx + 1);
            const sum = slice.reduce((acc, val) => acc + val.close, 0);
            return { date: d.date, value: sum / windowSize };
        }).filter((d) => d);
    }

    static calculateEMA(data, windowSize) {
        const k = 2 / (windowSize + 1);
        const emaArray = [];
        data.forEach((d, idx) => {
            if (idx === 0) {
                emaArray.push({ date: d.date, value: d.close });
            } else {
                const prevEma = emaArray[idx - 1].value;
                const ema = d.close * k + prevEma * (1 - k);
                emaArray.push({ date: d.date, value: ema });
            }
        });
        return emaArray.slice(windowSize - 1);
    }

    static calculateBollingerBands(data, windowSize) {
        const bollingerData = [];
        for (let i = windowSize - 1; i < data.length; i++) {
            const slice = data.slice(i - windowSize + 1, i + 1);
            const mean = slice.reduce((acc, val) => acc + val.close, 0) / windowSize;
            const stdDev = Math.sqrt(
                slice.reduce((acc, val) => acc + Math.pow(val.close - mean, 2), 0) / windowSize
            );
            bollingerData.push({
                date: data[i].date,
                middle: mean,
                upper: mean + 2 * stdDev,
                lower: mean - 2 * stdDev,
            });
        }
        return bollingerData;
    }
}

export default DataClient;
