import {ethers} from "ethers";
import {getRpcProvider} from "../connectors/rpcProviders.js";

const aggregatorV3InterfaceABI  = [
    'function latestRoundData() view returns (uint80 roundId, int256 answer, uint256 startedAt, uint256 updatedAt, uint80 answeredInRound)'
];

export const getUsdPrice = async (chainId, token) => {
    try {
        if (!token.usdPriceOracle || !chainId) return null;
        const priceFeed = new ethers.Contract(token.usdPriceOracle, aggregatorV3InterfaceABI, getRpcProvider(chainId));
        const roundData = await priceFeed.latestRoundData();
        return Number(roundData.answer) / 1e8;
    } catch (error) {
        return null;
    }
};