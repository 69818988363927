import React, {useEffect, useState} from 'react';
import SwapOrder from './order/SwapOrder';
import NetworkSelector from '../../../components/NetworkSelector';
import OrderSeparator from './order/OrderSeparator';
import {useAccount} from 'wagmi';
import OrderPreview from './OrderPreview';
import Card from "../../../components/Card";
import {getAvailableRoutes, getNetworks} from "token-list";

const LimitOrderForm = ({setSelectedTokenPair}) => {
    const {isConnected} = useAccount();
    const [network, setNetwork] = useState(getNetworks()[0]);
    const [orders, setOrders] = useState([
        {
            tokenIn: getNetworks()[0].tokens[0],
            tokenOut: getNetworks()[0].tokens[4],
            amountIn: 1,
            conditions: [{type: 'price', value: '', operator: null}]
        }
    ]);
    const [isModalOpen, setModalOpen] = useState(false);

    useEffect(() => {
        let tokenIn = network.tokens[Object.keys(network.tokens)[0]];
        let availableRoutes = getAvailableRoutes(network.chainId, tokenIn);
        let tokenOut = availableRoutes.values().next().value;
        setOrders([
            {
                tokenIn: tokenIn,
                tokenOut: tokenOut,
                amountIn: 1,
                conditions: [{type: 'price', value: '', operator: null}]
            }
        ]);
    }, [network]);

    const addOrder = () => {
        if (orders.length >= 2) return;
        const lastOrder = orders[orders.length - 1];
        setOrders([
            ...orders,
            {
                tokenIn: lastOrder.tokenOut,
                tokenOut: '',
                amountIn: '',
                conditions: [{type: 'price', value: '', operator: null}]
            }
        ]);
    };

    const removeLastOrder = () => setOrders(orders.slice(0, -1));

    const handleOrderChange = (index, updatedOrder) => {
        const updatedOrders = orders.map((order, i) =>
            i === index ? updatedOrder : order
        );
        setOrders(updatedOrders);
        setSelectedTokenPair({tokenIn: updatedOrder.tokenIn, tokenOut: updatedOrder.tokenOut});
    };

    const handleSubmit = () => setModalOpen(true);
    const closeModal = () => setModalOpen(false);
    const isCreateButtonEnabled = isConnected && orders[0].amountIn > 0;

    return (
        <Card className="w-[500px]" name="Create Limit Order">
            <div className="mb-3">
                <NetworkSelector network={network} setNetwork={setNetwork}/>
            </div>

            {orders.map((order, index) => (
                <div key={index} className="mb-3">
                    {index > 0 && (
                        <div className="flex-grow border-t border-gray-300 mb-3 mt-5"></div>
                    )}
                    <SwapOrder
                        network={network}
                        orderIndex={index}
                        tokenIn={
                            index === 0
                                ? order.tokenIn
                                : orders[index - 1].tokenOut
                        }
                        setTokenIn={
                            index === 0
                                ? (tokenIn) =>
                                    handleOrderChange(index, {
                                        ...order,
                                        tokenIn
                                    })
                                : null
                        }
                        amountIn={order.amountIn}
                        setAmountIn={(amountIn) =>
                            handleOrderChange(index, {...order, amountIn})
                        }
                        tokenOut={order.tokenOut}
                        setTokenOut={(tokenOut) =>
                            handleOrderChange(index, {...order, tokenOut})
                        }
                        conditions={order.conditions}
                        setConditions={(conditions) =>
                            handleOrderChange(index, {...order, conditions})
                        }
                    />
                </div>
            ))}

            <OrderSeparator
                addOrder={addOrder}
                removeOrder={removeLastOrder}
                showRemove={orders.length > 1}
                showAdd={orders.length < 2}
            />

            <div className="flex text-sm font-medium">
                <button
                    className="bg-blue-500 text-white py-2 px-5 rounded-lg shadow hover:bg-blue-600 disabled:bg-gray-400 disabled:cursor-not-allowed"
                    onClick={handleSubmit}
                    disabled={!isCreateButtonEnabled}
                >
                    Create Order
                </button>
            </div>

            <OrderPreview
                isOpen={isModalOpen}
                onClose={closeModal}
                network={network}
                orders={orders}
            />
        </Card>
    );
};

export default LimitOrderForm;
