import React from 'react';
import PriceConditionInput from './PriceConditionInput';
import SmaConditionInput from './SmaConditionInput';
import RsiConditionInput from './RsiConditionInput';
import BollingerBandsConditionInput from './BollingerBandsConditionInput';
import {FaDeleteLeft} from "react-icons/fa6";
import {MdAddBox} from "react-icons/md";

const ConditionWrapper = ({
                              index,
                              condition,
                              updateCondition,
                              removeCondition,
                              conditionsLength,
                              addCondition,
                              tokenIn,
                              tokenOut,
                              conditions, // Receive the conditions array
                              conditionTypes, // Receive the condition types with labels
                          }) => {
    // Get the list of selected condition types excluding the current one
    const selectedConditionTypes = conditions
        .filter((_, i) => i !== index)
        .map((cond) => cond.type);

    // Determine if we can add more conditions
    const availableConditionTypes = conditionTypes.filter(
        (type) => !conditions.map((cond) => cond.type).includes(type.value)
    );
    const canAddMoreConditions = conditionsLength < 5 && availableConditionTypes.length > 0;

    return (
        <div className="col-span-3">
            <div className="grid grid-cols-3 gap-2 items-center">
                <div className="col-span-1 flex space-x-2 mt-4 text-sm">
                    {index > 0 && (
                        <select
                            value={condition.operator || 'AND'}
                            onChange={(e) => {
                                updateCondition(index, {
                                    ...condition,
                                    operator: e.target.value,
                                });
                            }}
                            className="w-1/2"
                        >
                            <option value="AND">AND</option>
                            <option value="OR">OR</option>
                            <option value="XOR">XOR</option>
                        </select>
                    )}
                    <select
                        value={condition.type}
                        onChange={(e) => {
                            const updatedCondition = { ...condition, type: e.target.value };
                            updateCondition(index, updatedCondition);
                        }}
                        className={index > 0 ? 'w-1/2' : 'w-full'}
                    >
                        {conditionTypes.map((option) => (
                            <option
                                key={option.value}
                                value={option.value}
                                disabled={
                                    selectedConditionTypes.includes(option.value) &&
                                    option.value !== condition.type
                                }
                            >
                                {option.label}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="col-span-2">
                    {condition.type === 'price' && (
                        <PriceConditionInput
                            condition={condition}
                            setCondition={(updatedCond) => updateCondition(index, updatedCond)}
                            selectedTokenIn={tokenIn}
                            selectedTokenOut={tokenOut}
                        />
                    )}
                    {condition.type === 'sma' && (
                        <SmaConditionInput
                            condition={condition}
                            setCondition={(updatedCond) => updateCondition(index, updatedCond)}
                        />
                    )}
                    {condition.type === 'rsi' && (
                        <RsiConditionInput
                            condition={condition}
                            setCondition={(updatedCond) => updateCondition(index, updatedCond)}
                        />
                    )}
                    {condition.type === 'bollinger-bands' && (
                        <BollingerBandsConditionInput
                            condition={condition}
                            setCondition={(updatedCond) => updateCondition(index, updatedCond)}
                        />
                    )}
                </div>
            </div>
            {(conditionsLength > 1 || canAddMoreConditions) && (
                <div className="flex justify-end mt-1.5 space-x-2">
                    {conditionsLength > 1 && (
                        <FaDeleteLeft onClick={() => removeCondition(index)} className="text-red-500"/>
                    )}
                    {canAddMoreConditions && index === conditionsLength - 1 && (
                        <MdAddBox onClick={addCondition} className="text-green-600"/>
                    )}
                </div>
            )}
        </div>
    );
};

export default ConditionWrapper;
